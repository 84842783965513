import {
  FormControl,
  Input,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material"
import TextField from "@mui/material/TextField"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { Controller, useForm } from "react-hook-form"
import { getAccessToken, getSessionToken, pattern } from "./formUtils"
import "./forms.scss"
import { useIntl } from "gatsby-plugin-react-intl"
import axios from "axios"
import queryString, { parse } from "query-string"
import { getWebformURL } from "../../functions"
import { Link, navigate } from "gatsby"
import * as moment from "moment"
import countriesJson from './countries.json'
import { MuiTelInput } from "mui-tel-input"
import { hotelOptions } from "../../../services/form.service"
import { useContext } from "react"
import CartContext from "../../../context/CartContext"
import ConfirmPopUp from "./confirmPopUp"

const RequestQuoteHotelFlight = ({ thankYouPage }) => {

  const { setShow } = useContext(CartContext)
  const isSSR = typeof window === "undefined"
  const intl = useIntl()
  const locale = intl.locale

  // Get hotel value from url
  const toProperCase = s => {
    return s.replace(/([a-z])([A-Z])/g, "$1 $2")
  }

  let parsed, hotelQuery
  if (!isSSR) {
    parsed = queryString.parse(window.location.search)
    hotelQuery = parsed?.hotel ? toProperCase(parsed.hotel) : ""
  }

  const [value, setValue] = React.useState([null, null])
  const [firstName, setFirstName] = useState("")
  const [lastName, setlastName] = useState("")
  const [fullName, setFullName] = useState("")

  useEffect(() => {
    const inputPhone = document.querySelector("#telephone")
    inputPhone.setAttribute("maxlength", 15)
  }, [])

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    formState,
  } = useForm()

  const [phoneNumberError, setPhoneNumberError] = useState(false)
  const [formResponse, setFormResponse] = useState("")
  const { isSubmitSuccessful, isSubmitting, isValid } = formState
  const [submitted, setSubmitted] = useState(false)
  const [isFormValid, setIsFormValid] = React.useState(true)

  const submit = token => {}

  const [formClass, setFormClass] = useState("")

  let classHide = !isSubmitting && isSubmitSuccessful ? "" : ""

  if (formClass != "") {
    classHide = formClass
  }

  const [departVal, setDepartVal] = React.useState(null)
  const [phoneNumber, setPhoneNumber] = React.useState("")

  const handlePhoneChange = value => {
    if (value === null) {
      setPhoneNumberError(true)
      setPhoneNumber("")
    } else {
      setPhoneNumberError(false)
      setPhoneNumber(value)
    }
  }

  const handleKeyUpPhone = event => {
    if(event.target.value.length < 6) {
      setPhoneNumberError(true)
      setSubmitted(false)
    }
  }

  const handleSubmitForm = () => {
    
    if (phoneNumber === "" || phoneNumber.length < 6) {
      setPhoneNumberError(true)
    } else {
      setPhoneNumberError(false)
    }

    if (phoneNumber === "" || phoneNumber.length < 6 || departVal === null) return 
    setSubmitted(true)
  }

  //document.referrer
  useEffect(()=> {
    setFullName(`${firstName} ${lastName}`)
  },[firstName, lastName])


  const submitHandlerContact = async (values, e) => {
    e.preventDefault()

    if(!submitted) return 

    setIsFormValid(false)
    setShow("show")

    if (values.name) {
      setFullName(values.name + " " + values.surname)
    }

    // Handle required dropdown - post false value to API if "No" option is selected
    if (values.already_booked_your_flight === "No") {
      values.already_booked_your_flight = ""
    }

    if (departVal) {
      values.date_of_departure = moment(departVal).format("YYYY/MM/DD")
    }
    values.telephone = phoneNumber

    let currentPage, prevPage, initial_referrer

    if (!isSSR) {
      currentPage = window.location.href
      prevPage = document.referrer
    }

    values.gdpr = values.gdpr === "Yes"
    values.marque = "Veranda Resorts"
    values.business_unit = "Flight"
    values.type_of_form = "Veranda Resorts - Flight and Hotel"
    values.lead_source = "Web Site"
    values.last_referrer = currentPage

    values.country === "Mauritius"
      ? (values.devise_de_la_piste = "MUR")
      : (values.devise_de_la_piste = "EUR")
    intl.locale === "en"
      ? (values.language_id = "en_US")
      : (values.language_id = "fr")
    intl.locale === "en"
      ? (values.language = "English")
      : (values.language = "French")
    values.initial_landing_page = prevPage
    values.origine_de_la_piste = prevPage
    values.utm_campaign =
      parsed?.utm_campaign !== undefined ? parsed.utm_campaign : ""
    values.utm_content =
      parsed?.utm_content !== undefined ? parsed.utm_content : ""
    values.utm_medium =
      parsed?.utm_medium !== undefined ? parsed.utm_medium : ""
    values.utm_source =
      parsed?.utm_source !== undefined ? parsed.utm_source : ""
    values.utm_term = parsed?.utm_term !== undefined ? parsed.utm_term : ""
    values.form_source =
      parsed?.form_source !== undefined ? parsed.form_source : ""
    values.gclid = parsed?.gclid !== undefined ? parsed.gclid : ""
    values.promo_code =
      parsed?.promo_code !== undefined ? parsed.promo_code : ""
    values.special_offers_ref =
      parsed?.special_offers_ref !== undefined ? parsed.special_offers_ref : ""
    values.special_offer =
      parsed?.special_offers !== undefined ? parsed.special_offers : ""
    values.initial_referrer = prevPage
    values.subject =
      intl.locale === "en"
        ? "Form submission from: Hotel & Flight"
        : "Form submission from: Hôtel et vol"

    const body = {
      webform_id: "webform_hotel_flight",
      values: values,
    }
    
    try {
      const sessionToken = await getSessionToken();
      const accessToken = await getAccessToken();
      const options = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "X-CSRF-Token": sessionToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
      };
      const response = await axios({
        method: "POST",
        url: getWebformURL(intl.locale),
          data: JSON.stringify(body),
          headers: options,
      });
      e.target.reset();

    } catch (error) {
      // console.log("submitFormError: ", error);
      setFormResponse("");
    }

  }

  const [country, setCountry] = React.useState("")

  const handleChange = event => {
    setCountry(event.target.value)
  }

  let countriesArr;
  if (intl.locale === "en") {
    countriesArr = countriesJson.countriesEn
  } else {
    countriesArr = countriesJson.countriesFr
  }

  return (
    <section className="blk-form pb-50 pt-50">
      <ConfirmPopUp formSubmitted={submitted}/>
      <Container>
        <form onSubmit={handleSubmit(submitHandlerContact)} noValidate={true}>
          <div className={classHide}>
            <Row>
              <Col>
                <FormControl sx={{ m: 1, width: 430 }} required error={errors.hotel}>
                  <InputLabel htmlFor="hotel">
                    {intl.formatMessage({ id: "form_inputs.hotel" })}
                  </InputLabel>
                  <Select
                    id="hotel"
                    label="hotel"
                    defaultValue={hotelQuery}
                    {...register("hotel", { required: true })}
                  >
                    {hotelOptions.map((hotel, index) => (
                      <MenuItem key={index} value={hotel.value}>
                        {hotel.label}
                    </MenuItem>
                    ))}
                  </Select>
                  {errors.hotel && errors.hotel.type === "required" && (
                    <FormHelperText>
                      {intl.formatMessage({ id: "form_validation.required" })}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl className="blkCalendar" sx={{ m: 1, width: 430 }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <InputLabel htmlFor="date_of_departure">
                      {intl.formatMessage({ id: "form_inputs.departure_date" })}
                    </InputLabel>
                    <DatePicker
                      id="date_of_departure"
                      inputFormat="yyyy/MM/dd"
                      minDate={new Date()}
                      value={departVal}
                      onChange={newValue => {
                        setDepartVal(newValue)
                      }}
                      renderInput={params => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  {errors.date_of_departure &&
                    errors.date_of_departure.type === "required" && (
                      <FormHelperText>
                        {intl.formatMessage({ id: "form_validation.required" })}
                      </FormHelperText>
                    )}
                </FormControl>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormControl sx={{ m: 1, width: 430 }} required error={errors.flying_from}>
                  <InputLabel htmlFor="flying_from">
                    {intl.formatMessage({
                      id: "form_inputs.flying_from",
                    })}
                  </InputLabel>
                  <Select
                    id="flying_from"
                    label="flying_from"
                    {...register("flying_from", {
                      required: true,
                    })}
                  >
                    <MenuItem value="Paris">Paris</MenuItem>
                    <MenuItem value="Lyon">Lyon</MenuItem>
                    <MenuItem value="Nice">Nice</MenuItem>
                    <MenuItem value="Marseille">Marseille</MenuItem>
                    <MenuItem value="Toulouse">Toulouse</MenuItem>
                    <MenuItem value="Nantes">Nantes</MenuItem>
                    <MenuItem value="Bordeaux">Bordeaux</MenuItem>
                    <MenuItem value="Montpellier">Montpellier</MenuItem>
                    <MenuItem value="Bâle-Mulhouse">Bâle-Mulhouse</MenuItem>
                    <MenuItem value="Saint-Denis">Saint-Denis</MenuItem>
                    <MenuItem value="Bruxelles">Bruxelles</MenuItem>
                    <MenuItem value="Genève">Genève</MenuItem>
                    <MenuItem value="Autres">Autres</MenuItem>
                  </Select>
                  {errors.flying_from && errors.flying_from.type === "required" && (
                      <FormHelperText>
                        {intl.formatMessage({ id: "form_validation.required" })}
                      </FormHelperText>
                    )}
                </FormControl>
                <FormControl
                  sx={{ m: 1, width: 430 }}
                  required
                  error={errors.flexible_with_dates}
                >
                  <InputLabel htmlFor="flexible_with_dates">
                    {intl.formatMessage({
                      id: "form_inputs.flexible_dates",
                    })}
                  </InputLabel>
                  <Select
                    id="flexible_with_dates"
                    label="flexible_with_dates"
                    {...register("flexible_with_dates", {
                      required: true,
                    })}
                  >
                    {Array.from(Array(16), (e, i) => {
                      if (i === 0) {
                        return (
                          <MenuItem value={i}>{i}{" "}{intl.formatMessage({id: "form_inputs.day"})}</MenuItem>
                        )
                      }
                      return <MenuItem value={i}>{i}{" "}{intl.formatMessage({id: "form_inputs.days"})}</MenuItem>
                    })}
                  </Select>
                  {errors.flexible_with_dates &&
                    errors.flexible_with_dates.type === "required" && (
                      <FormHelperText>
                        {intl.formatMessage({ id: "form_validation.required" })}
                      </FormHelperText>
                    )}
                </FormControl>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormControl
                  sx={{ m: 1, width: 430 }}
                  required
                  error={errors.number_of_nights}
                >
                  <InputLabel htmlFor="number_of_nights">
                    {intl.formatMessage({
                      id: "form_inputs.number_nights",
                    })}
                  </InputLabel>
                  <Select
                    id="number_of_nights"
                    label="number_of_nights"
                    {...register("number_of_nights", {
                      required: true,
                    })}
                  >
                    <MenuItem value="0">0</MenuItem>
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                    <MenuItem value="6">6</MenuItem>
                    <MenuItem value="7">7</MenuItem>
                    <MenuItem value="8">8</MenuItem>
                    <MenuItem value="9">9</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="11">11</MenuItem>
                    <MenuItem value="12">12</MenuItem>
                    <MenuItem value="13">13</MenuItem>
                    <MenuItem value="14">14</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="16">16</MenuItem>
                    <MenuItem value="17">17</MenuItem>
                    <MenuItem value="18">18</MenuItem>
                    <MenuItem value="19">19</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                  </Select>
                  {errors.number_of_nights &&
                    errors.number_of_nights.type === "required" && (
                      <FormHelperText>
                        {intl.formatMessage({ id: "form_validation.required" })}
                      </FormHelperText>
                    )}
                </FormControl>
                <FormControl
                  sx={{ m: 1, width: 430 }}
                  required
                  error={errors.adults}
                >
                  <InputLabel htmlFor="adults">
                    {intl.formatMessage({ id: "form_inputs.adults" })}
                  </InputLabel>
                  <Select
                    id="adults"
                    label="adults"
                    {...register("adults", { required: true })}
                  >
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                    <MenuItem value="6">6</MenuItem>
                    <MenuItem value="7">7</MenuItem>
                    <MenuItem value="8">8</MenuItem>
                    <MenuItem value="9">9</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="11">11</MenuItem>
                    <MenuItem value="12">12</MenuItem>
                    <MenuItem value="13">13</MenuItem>
                    <MenuItem value="14">14</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="15+">15+</MenuItem>
                  </Select>
                  {errors.adults && errors.adults.type === "required" && (
                    <FormHelperText>
                      {intl.formatMessage({ id: "form_validation.required" })}
                    </FormHelperText>
                  )}
                </FormControl>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormControl sx={{ m: 1, width: 430 }}>
                  <InputLabel htmlFor="teen">
                    {intl.formatMessage({ id: "form_inputs.teen" })}
                  </InputLabel>
                  <Select
                    id="teen"
                    label="teen"
                    {...register("teen", { required: false })}
                  >
                    <MenuItem value="0">0</MenuItem>
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                    <MenuItem value="6">6</MenuItem>
                    <MenuItem value="7">7</MenuItem>
                    <MenuItem value="8">8</MenuItem>
                    <MenuItem value="9">9</MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, width: 430 }}>
                  <InputLabel htmlFor="children">
                    {intl.formatMessage({ id: "form_inputs.children" })}
                  </InputLabel>
                  <Select
                    id="children"
                    label="children"
                    {...register("children", { required: false })}
                  >
                    <MenuItem value="0">0</MenuItem>
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                    <MenuItem value="6">6</MenuItem>
                    <MenuItem value="7">7</MenuItem>
                    <MenuItem value="8">8</MenuItem>
                    <MenuItem value="9">9</MenuItem>
                  </Select>
                </FormControl>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormControl sx={{ m: 1, width: 430 }}>
                  <InputLabel htmlFor="infant">
                    {intl.formatMessage({ id: "form_inputs.infant" })}
                  </InputLabel>
                  <Select
                    id="infant"
                    label="infant"
                    {...register("infant", { required: false })}
                  >
                    <MenuItem value="0">0</MenuItem>
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                    <MenuItem value="6">6</MenuItem>
                    <MenuItem value="7">7</MenuItem>
                    <MenuItem value="8">8</MenuItem>
                    <MenuItem value="9">9</MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  sx={{ m: 1, width: 430 }}
                  required
                  error={errors.title}
                >
                  <InputLabel htmlFor="title">
                    {intl.formatMessage({ id: "form_inputs.title" })}
                  </InputLabel>
                  <Select
                    id="title"
                    label="title"
                    {...register("title", { required: true })}
                  >
                    <MenuItem value="Mr.">
                      {locale == "en" ? "Mr." : "M."}
                    </MenuItem>
                    <MenuItem value="Mrs.">
                      {locale == "en" ? "Mrs." : "Mme"}
                    </MenuItem>
                    <MenuItem value="Ms.">
                      {locale == "en" ? "Ms." : "Mlle"}
                    </MenuItem>
                  </Select>
                  {errors.title && errors.title.type === "required" && (
                    <FormHelperText>
                      {intl.formatMessage({ id: "form_validation.required" })}
                    </FormHelperText>
                  )}
                </FormControl>
              </Col>
            </Row>
            <Row>
              <Col className="contact-details">
                <FormControl required error={errors.name}>
                  <InputLabel htmlFor="name">
                    {intl.formatMessage({ id: "form_inputs.first_name" })}
                  </InputLabel>
                  <Input
                    id="name"
                    name="name"
                    onKeyUp={(e)=> {
                      setFirstName(e.target.value)
                    }}
                    {...register("name", {
                      required: true,
                      pattern: pattern.name,
                    })}
                  />
                  {errors.name && errors.name.type === "required" && (
                    <FormHelperText>
                      {intl.formatMessage({
                        id: "form_validation.required",
                      })}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl required error={errors.surname}>
                  <InputLabel htmlFor="surname">
                    {intl.formatMessage({ id: "form_inputs.last_name" })}
                  </InputLabel>
                  <Input
                    id="surname"
                    name="surname"
                    onKeyUp={(e)=> {
                      setFirstName(e.target.value)
                    }}
                    {...register("surname", {
                      required: true,
                    })}
                  />
                  {errors.surname && errors.surname.type === "required" && (
                    <FormHelperText>
                      {intl.formatMessage({
                        id: "form_validation.required",
                      })}
                    </FormHelperText>
                  )}
                </FormControl>
              </Col>
            </Row>
            <Row>
              <Col className="colPhoneNum">
                <FormControl
                  sx={{ m: 1, width: 373 }}
                  //style={{ right: "26px", paddingRight: "0" }}
                >
                  <InputLabel htmlFor="country">
                    {intl.formatMessage({ id: "form_inputs.country" })}
                  </InputLabel>
                  <Select
                    id="country"
                    label="Country"
                    onChange={handleChange}
                    {...register("country", { required: false })}
                  >
                    {countriesArr.map((country, index) => {
                      return (
                        <MenuItem key={index} value={country.en}>
                          {intl.locale === "en" ? country.en : country.fr}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
                <FormControl
                  className="blkPhoneNum"
                  error={errors.telephone}
                  required
                >
                  <InputLabel htmlFor="telephone">
                    {intl.formatMessage({ id: "form_inputs.telephone" })}
                  </InputLabel>
                  <MuiTelInput 
                    id="telephone"
                    disableFormatting 
                    forceCallingCode
                    onKeyUp={handleKeyUpPhone}
                    onChange={handlePhoneChange}
                    value={phoneNumber}
                    defaultCountry={"FR"}
                    langOfCountryName={intl.locale}
                  />
                  {errors.telephone && errors.telephone.type === "required" && (
                    <FormHelperText>
                      {intl.formatMessage({ id: "form_validation.required" })}
                    </FormHelperText>
                  )}
                  <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained Mui-required css-1wc848c-MuiFormHelperText-root">
                    {(phoneNumberError ||
                      (submitted && value === "")) && (
                      <span className="error" severity="error">
                        {intl.formatMessage({
                          id: "form_validation.required",
                        })}
                      </span>
                    )}{" "}
                  </p>
                  {errors.telephone && errors.telephone.type === "required" && (
                    <FormHelperText>
                      {intl.formatMessage({ id: "form_validation.required" })}
                    </FormHelperText>
                  )}
                </FormControl>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormControl required error={errors.email}>
                  <InputLabel htmlFor="email">
                    {intl.formatMessage({ id: "form_inputs.email" })}
                  </InputLabel>
                  <Input
                    id="email"
                    {...register("email", {
                      required: true,
                      pattern: pattern.email,
                    })}
                  />
                  {errors.email && errors.email.type === "pattern" && (
                    <FormHelperText>
                      {intl.formatMessage({
                        id: "form_validation.email_pattern",
                      })}
                    </FormHelperText>
                  )}
                  {errors.email && errors.email.type === "required" && (
                    <FormHelperText>
                      {intl.formatMessage({ id: "form_validation.required" })}
                      </FormHelperText>
                    )}
                </FormControl>
              </Col>
            </Row>
            <Row>
              <Col className="contact-message">
                <FormControl required error={errors.enquiry_details} fullWidth>
                  <InputLabel htmlFor="enquiry_details">
                    {intl.formatMessage({ id: "form_inputs.message" })}
                  </InputLabel>
                  <Input
                    id="enquiry_details"
                    {...register("enquiry_details", { required: true })}
                    multiline
                  />
                  {errors.enquiry_details &&
                    errors.enquiry_details.type === "required" && (
                      <FormHelperText>
                        {intl.formatMessage({ id: "form_validation.required" })}
                      </FormHelperText>
                    )}
                </FormControl>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormControl
                  required
                  error={errors.gdpr}
                  className="radio-form"
                >
                  <Controller
                    rules={{ required: true }}
                    control={control}
                    name="gdpr"
                    render={({ field }) => (
                      <RadioGroup {...field}>
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label={intl.formatMessage({
                            id: "form_inputs.opt_in_yes",
                          })}
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label={intl.formatMessage({
                            id: "form_inputs.opt_in_no",
                          })}
                        />
                      </RadioGroup>
                    )}
                  />
                  {errors.gdpr && errors.gdpr.type === "required" && (
                    <FormHelperText className="helper-required">
                      {intl.formatMessage({ id: "form_validation.required" })}
                    </FormHelperText>
                  )}
                  <FormHelperText className="form-helper-policy">
                    {intl.formatMessage({ id: "form_inputs.opt_in_helper" })}{" "}
                    <Link
                      to={intl.formatMessage({ id: "privacy_policy.link" })}
                    >
                      {intl.formatMessage({ id: "privacy_policy.title" })}
                    </Link>
                  </FormHelperText>
                </FormControl>
              </Col>
            </Row>
            <Row className="contact-submit">
              {/*<ReCaptcha*/}
              {/*  action="request_quote"*/}
              {/*  siteKey="6Lc-c8QSAAAAAERYd1S2PLKI3r0LuswOx9CRE1ku"*/}
              {/*  onVerify={token => submit(token)}*/}
              {/*  submitted={submitted}*/}
              {/*/>*/}
              <button
                className={`btn ${
                  !isFormValid ? "disabled" : "btnPrimary"
                }`}
                onClick={handleSubmitForm}
                disabled={!isFormValid ? true : ""}
                type="submit"
              >
                <span>{intl.formatMessage({ id: "form_inputs.send" })}</span>
              </button>
            </Row>
          </div>
          {formResponse && (
            <Row className="success-elem">
              <Col>
                <div className="form-message" style={{ color: "red" }}>
                  {formResponse}
                </div>
              </Col>
            </Row>
          )}
          {!isSubmitting &&
            isSubmitSuccessful &&
            !formResponse && submitted &&
            (intl.locale === "en"
              ? navigate(
                  `${thankYouPage.url}?titre=askquote-hotel-flight&name=${fullName}`
                )
              : navigate(
                  `${thankYouPage.url}?titre=askquote-hotel-vol&name=${fullName}`
                ))}
        </form>
      </Container>
    </section>
  )
}

export default RequestQuoteHotelFlight
