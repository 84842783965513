import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import MainBannerImage from "../components/common/mainBanner/mainBannerImage"
import { graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-react-intl"
import { setSeoData } from "../services/seo.service"
import { getCarouselImageOG } from "../components/functions"
import RequestQuote from "../components/common/forms/requestQuote"
import Contact from "../components/common/forms/contact"
import RequestQuoteHotelFlight from "../components/common/forms/requestQuoteHotelFlight"
import RequestQuoteExperience from "../components/common/forms/requestQuoteExperience"

export default function Form({ data: { nodeFormPage }, pageContext }) {
  const {
    title,
    field_seo,
    form_page_webform: {
      drupal_internal__target_id: formName,
    },
    form_page_thank_you_page,
    relationships: {
        form_page_carousel: {
            relationships: { carousel_highlight_item: carouselMea },
        },
    }
  } = nodeFormPage

  const intl = useIntl()

  const carouselFiltered = carouselMea.filter(
    carousel => carousel.relationships.media !== null
  )

  const seoData = setSeoData({
    lang: intl.locale,
    title: title,
    field_seo: field_seo,
    image: process.env.GATSBY_URL + getCarouselImageOG(carouselFiltered),
  })

  const Form = () => {
    switch (formName) {
      case "webform_request_a_quote":
        return <RequestQuote thankYouPage={form_page_thank_you_page} />
      case "webform_contact":
        return <Contact thankYouPage={form_page_thank_you_page} />
      case "webform_hotel_flight":
        return <RequestQuoteHotelFlight thankYouPage={form_page_thank_you_page} />
      case "collectable_experiences_request_":
        return <RequestQuoteExperience thankYouPage={form_page_thank_you_page} />
      default:
        break;
    }
  }

  //console.log(formName)

  return (
    <Layout pageContext={pageContext} pageTitle={title} pageClass="pageForms">
       <Seo data={seoData} pageContext={pageContext} />
      {carouselFiltered.length > 0 && (
        <MainBannerImage items={carouselFiltered} isHeightSmall={1} />
      )}
      <Form />
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    nodeFormPage(id: { eq: $id }) {
        title
        field_seo {
          description
          title
          og_description
          og_image
          og_locale
          og_url
          og_title
          og_type
        }
        form_page_webform {
          drupal_internal__target_id
        }
        form_page_thank_you_page {
          title
          url
        }
        relationships {
            form_page_carousel {
            relationships {
              carousel_highlight_item {
                title
                caption
                subtitle
                cta {
                  title
                  uri
                  url
                }
                relationships {
                  full_screen_video {
                    field_media_oembed_video
                  }
                  media {
                    ... on media__image {
                      field_image {
                        alt
                      }
                      internal {
                        type
                      }
                      relationships {
                        field_image {
                          gatsbyImage(breakpoints: [1920], width: 1920, formats: WEBP, layout: FULL_WIDTH)
                        }
                      }
                    }
                    ... on media__wistia {
                      internal {
                        type
                      }
                      field_media_oembed_video
                      relationships {
                        thumbnail {
                          gatsbyImage(width: 1920, formats: WEBP)
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
    }
  }
`
