import {
  FormControl,
  Input,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  FormControlLabel,
  RadioGroup,
  Radio,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { Controller, useForm } from "react-hook-form"
import { getAccessToken, getSessionToken, pattern } from "./formUtils"
import "./forms.scss"
import { useIntl } from "gatsby-plugin-react-intl"
import axios from "axios"
import queryString from "query-string"
import { getWebformURL } from "../../functions"
import { Link, navigate } from "gatsby"
import { MuiTelInput } from "mui-tel-input"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { addDays } from "date-fns"
import * as moment from "moment"
import ReCaptcha from "@pittica/gatsby-plugin-recaptcha"
import countriesJson from "./countries.json"
import CollectableExperienceCart from "./CollectableExperienceCart"
import ConfirmPopUp from "./confirmPopUp"
import { useContext } from "react"
import CartContext from "../../../context/CartContext"
import { hotelOptions } from "../../../services/form.service"
const RequestQuoteExperience = ({ thankYouPage }) => {
  const intl = useIntl()
  const { experiences, deleteAll, setShow } = useContext(CartContext)
  const numRenderedCart = 5
  const numberWithCommas = target => {
    return target.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
  const experience = experiences.map(experience => {
    return `${experience.pageTitle}`
  })
  const numChild = experiences.map(experience => {
    let priceChild
    if (intl.locale === "en") {
      priceChild = `(Total:€${numberWithCommas(
        experience.numChild * experience.priceChild
      )})*`
      if (priceChild === `(Total:€0)*`) {
        priceChild = `0(Total:(€0)*`
        return `${experience.numChild}${priceChild}`
      }
      return `${experience.numChild}${priceChild}`
    } else {
      priceChild = `(Total:${numberWithCommas(
        experience?.numChild * experience.priceChild
      )}€)*`
      if (priceChild === `(Total:0€)*`) {
        priceChild = `0(Total:(0€)*`
        return `${experience.numChild}${priceChild}`
      }
      return `${experience.numChild}${priceChild}`
    }
  })
  const numAdults = experiences.map(experience => {
    return `${experience.numAdults}${intl.locale == "en" ? `(Total:€${numberWithCommas(experience.numAdults * experience.price)})*` : `(Total:${numberWithCommas(experience.numAdults * experience.price)}€)*`}`
  })
  const activityDate = experiences.map(experience => {
    return `${moment(experience.startDate).format("DD MMMM YYYY")}`
  })

  const isSSR = typeof window === "undefined"
  const locale = intl.locale

  // Get hotel value from url

  const toProperCase = s => {
    return s.replace(/([a-z])([A-Z])/g, "$1 $2")
  }

  let parsed, hotelQuery
  if (!isSSR) {
    parsed = queryString.parse(window.location.search)
    hotelQuery = parsed?.hotel ? toProperCase(parsed.hotel) : ""
  }

  const [firstName, setFirstName] = useState("")
  const [lastName, setlastName] = useState("")
  const [fullName, setFullName] = useState("")

  // Date Range Picker Value
  const [value, setValue] = React.useState([null, null])
  const [checkIn, setCheckIn] = React.useState("")
  const [checkOut, setCheckOut] = React.useState("")

  // DD/MM/YYYY - DD/MM/YYYY Format

  // const checkInDate = value[0] ? value[0].toLocaleDateString() : ""
  // const checkOutDate = value[1] ? value[0].toLocaleDateString() : ""

  let newDate = new Date()
  let date = newDate.getDate()
  let month = newDate.getMonth() + 1
  let year = newDate.getFullYear()

  const travellingDate = date + "/" + month + "/" + year

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    formState,
  } = useForm()

  const [formResponse, setFormResponse] = useState("")
  const { isSubmitSuccessful, isSubmitting, isValid } = formState
  const [submitted, setSubmitted] = useState(false)
  const submit = token => {}

  const [formClass, setFormClass] = useState("")

  let classHide = !isSubmitting && isSubmitSuccessful ? "" : ""

  if (formClass != "") {
    classHide = formClass
  }
  const [isFormValid, setIsFormValid] = React.useState(true)
  const [phoneNumber, setPhoneNumber] = React.useState("")
  const [whichPlatform, setWhichPlatform] = React.useState("")
  const [whichPlatformValue, setWhichPlatformValue] = useState("none")
  // const [alreadyBookedFlight, setAlreadyBookedFlight] = React.useState("")
  const [alreadyBookedRoom, setAlreadyBookedRoom] = React.useState("")
  const [willBookRoom, setWillBookRoom] = useState("")
  const [msgXp, setMsgXp] = useState("")
  const experienceExist = experiences.some(exp => exp.id === experience.id)
  const [isPendingSubmit, setIsPendingSubmit] = useState(false)

  let isExpValid = true

  if (experiences.length === 0) {
    isExpValid = false
  }
  
  const handleWhichPlatform = (event, whichPlatform) => {
    event.preventDefault()
    if (whichPlatform === null) {
      setWhichPlatformError(true)
      setWhichPlatform("")
    } else {
      setWhichPlatformError(false)
      setWhichPlatform(whichPlatform)
    }
  }

  const handleWhichPlatformValue = (event, whichPlatformValue) => {
    if (whichPlatformValue === null) {
      setWhichPlatformValueError(true)
      setWhichPlatform("")
    } else {
      setWhichPlatformValueError(false)
      setWhichPlatform(whichPlatformValue)
    }
  }
  // const handleAlreadyBookFlightChange = (event, bookedFlightValue) => {
  //   setAlreadyBookedFlight(bookedFlightValue)
  // }
  const handleAlreadyBookRoomChange = (event, bookedRoomValue) => {
    if (bookedRoomValue === null) {
      setAlreadyBookedError(true)
      setAlreadyBookedRoom("")
    } else {
      setAlreadyBookedError(false)
      setAlreadyBookedRoom(bookedRoomValue)
    }
  }
  const handleWillBookRoomChange = (event, willBookedRoomValue) => {
    if (willBookedRoomValue === null) {
      setWiiBookError(true)
      setWillBookRoom("")
    } else {
      setWiiBookError(false)
      setWillBookRoom(willBookedRoomValue)
    }
  }

  const handlePhoneChange = value => {
    if (value === null) {
      setPhoneNumberError(true)
      setPhoneNumber("")
    } else {
      setPhoneNumberError(false)
      setPhoneNumber(value)
    }
  }

  const handleKeyUpPhone = event => {
    if(event.target.value.length < 6) {
      setPhoneNumberError(true)
      setSubmitted(false)
    }
  }

  // const [dateRange, setDateRange] = useState([
  //   new Date(),
  //   addDays(new Date(), 3),
  // ])
  // const [startDate, endDate] = dateRange
  const currentDate = new Date()
  const nextDate = new Date(currentDate)
  nextDate.setDate(currentDate.getDate() + 2)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(nextDate)

  const handleStartDateChange = date => {
    setStartDate(date)
    if (date) {
      const newEndDate = new Date(date)
      newEndDate.setDate(date.getDate() + 2)
      setEndDate(newEndDate)
    }
  }

  const handleEndDateChange = date => {
    setEndDate(date)
  }
  //Prevent Typing date
  const handleDateChangeRaw = e => {
    e.preventDefault()
  }
  
  useEffect(()=> {
    setFullName(`${firstName} ${lastName}`)
  },[firstName, lastName])

  const submitHandlerContact = async (values, e) => {
    e.preventDefault();

    if(!submitted) return
    setIsFormValid(false)
    setShow("show")
    
    if (values.first_name) {
      setFullName(values.first_name + " " + values.last_name)
    }

    values.telephone = phoneNumber
    values.already_booked_your_room = alreadyBookedRoom
    values.do_you_want_to_book_your_room = willBookRoom
    if (startDate) {
      values.arrival_date = moment(startDate).format("YYYY/MM/DD")
    }

    if (endDate) {
      values.departure_date = moment(endDate).format("YYYY/MM/DD")
    }

    if (willBookRoom === "No") {
      values.marque = "eConciergerie"
      values.hotel = "Outsider"
      values.business_unit = "eConciergerie"
    } else {
      values.business_unit = "Direct Sales"
      values.marque = "Veranda Resorts"
    }

    if (alreadyBookedRoom === "Yes") {
      values.marque = "Veranda Resorts"
      values.business_unit = "eConciergerie"
    }

    if (startDate && endDate) {
      const startDateMoment = moment(startDate, "YYYY-MM-DD").startOf("day")
      const endDateMoment = moment(endDate, "YYYY-MM-DD")

      values.number_of_nights = endDateMoment.diff(startDateMoment, "days")
    }

    let currentPage, prevPage, initial_referrer

    if (!isSSR) {
      currentPage = window.location.href
      prevPage = document.referrer
    }

    values.type_of_form = "Veranda Resorts - Request Quote Form"
    values.lead_source = "Plan your Stay"
    values.last_referrer = currentPage
    values.which_online_platform_did_you_use = values.which_agency_did_you_use
      ? ""
      : values.which_online_platform_did_you_use != undefined
      ? values.which_online_platform_did_you_use
      : ""
    values.which_platform_did_you_use =
      whichPlatform != undefined ? whichPlatform : ""
    values.country === "Mauritius"
      ? (values.devise_de_la_piste = "MUR")
      : (values.devise_de_la_piste = "EUR")
    values.country === "Mauritius"
      ? (values.currency = "MUR")
      : (values.currency = "EUR")
    intl.locale === "en"
      ? (values.language_id = "en_US")
      : (values.language_id = "fr")
    intl.locale === "en"
      ? (values.language = "English")
      : (values.language = "French")
    values.initial_landing_page = prevPage
    values.origine_de_la_piste = prevPage
    values.utm_campaign =
      parsed?.utm_campaign !== undefined ? parsed.utm_campaign : ""
    values.utm_content =
      parsed?.utm_content !== undefined ? parsed.utm_content : ""
    values.utm_medium =
      parsed?.utm_medium !== undefined ? parsed.utm_medium : ""
    values.utm_source =
      parsed?.utm_source !== undefined ? parsed.utm_source : ""
    values.utm_term = parsed?.utm_term !== undefined ? parsed.utm_term : ""
    values.form_source =
      parsed?.form_source !== undefined ? parsed.form_source : ""
    values.gclid = parsed?.gclid !== undefined ? parsed.gclid : ""
    values.promo_code =
      parsed?.promo_code !== undefined ? parsed.promo_code : ""
    values.special_offers_ref =
      parsed?.special_offers_ref !== undefined ? parsed.special_offers_ref : ""
    values.special_offer =
      parsed?.special_offers !== undefined ? parsed.special_offers : ""
    values.initial_referrer = prevPage
    values.subject =
      intl.locale === "en"
        ? "Form submission from: Reserve your experiences"
        : "Form submission from: Réservez vos expériences"
    if (whichPlatform === "Directement avec nous") {
      values.which_agency_did_you_use = ""
      values.which_online_platform_did_you_use = ""
      values.market = ""
    } else if (whichPlatform === "Agence de voyage en ligne") {
      values.market = "OTA"
    } else if (whichPlatform === "Agence de voyage") {
      values.market = "TO"
    }

    values.message = `
    Message : ${values.contact_message}
    Have you already reserve your room : ${alreadyBookedRoom}
    Which platform : ${whichPlatform}
    Platform/Agency name : ${
      values.which_agency_did_you_use
        ? values.which_agency_did_you_use
        : values.which_online_platform_did_you_use
        ? values.which_online_platform_did_you_use
        : ""
    }
    Would you want to reserve your room : ${willBookRoom}
    Experiences : ${experience}
    Date of activity: ${activityDate}
    Number of adults: ${numAdults}
    Number of children: ${numChild}
    Mauritian Citizen: ${values.resident}`
    const body = {
      webform_id: "collectable_experiences_request_",
      values: values,
    }
    try {
      const sessionToken = await getSessionToken()
      const accessToken = await getAccessToken()
      const options = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "X-CSRF-Token": sessionToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      }
      const response = await axios({
        method: "POST",
        url: getWebformURL(intl.locale),
        data: JSON.stringify(body),
        headers: options,
      })

      await deleteAllAsync(experiences)

      window.location.href = `${thankYouPage.url}?titre=askquote&name=${fullName}`

      e.target.reset()
    } catch (error) {
      // console.log("submitFormError: ", error);
      setFormResponse("")
    }
  }
  const [country, setCountry] = React.useState("")
  const [isResident, setIsResident] = useState("")
  const [alreadyBookedError, setAlreadyBookedError] = useState(false)
  const [wiiBookError, setWiiBookError] = useState(false)
  const [whichPlatformError, setWhichPlatformError] = useState(false)
  const [whichPlatformValueError, setWhichPlatformValueError] = useState(false)
  const [phoneNumberError, setPhoneNumberError] = useState(false)
  const [isExperiences, setIsExperiences] = useState(true)
  
  const handleChange = event => {
    setCountry(event.target.value)
  }

  const deleteAllAsync = async experiences => {
    // Implement the deleteAll function as an asynchronous function that returns a Promise.
    return new Promise(resolve => {
      // Perform the deleteAll operation.
      deleteAll(experiences)
      // Resolve the Promise after a short delay to ensure that the deleteAll operation has completed.
      setTimeout(resolve, 100)
    })
  }
  const handleSubmitForm = () => {
    if (alreadyBookedRoom === "") {
      setAlreadyBookedError(true)
      setSubmitted(false)
    } else {
      setAlreadyBookedError(false)
    }

    if (whichPlatform === "none") {
      setWhichPlatformError(true)
      setSubmitted(false)
    } else {
      setWhichPlatformError(false)
    }

    if (willBookRoom === "") {
      setWiiBookError(true)
      setSubmitted(false)
    } else {
      setWiiBookError(false)
    }

    if (phoneNumber === "" || phoneNumber.length < 6) {
      setPhoneNumberError(true)
    } else {
      setPhoneNumberError(false)
    }
    if (phoneNumber === "" || phoneNumber.length < 6) return 
    setSubmitted(true)
    
    if(submitted === true) {
      setIsPendingSubmit(true)
    }

  }

  // const handleChangeMsg = event => {
  //   const msgValue = document.querySelector(`#contact-message`).value
  //   const msgXp = `Message : ${msgValue}
  //   Have you already reserve your room : ${alreadyBookedRoom}
  //   Would you want to reserve your room : ${willBookRoom}
  //   Which media : ${whichPlatform}
  //   Experiences : ${experience}
  //   Date of activity: ${activityDate}
  //   Number of adults: ${numAdults}
  //   Number of children: ${numChild}`
  //   setMsgXp(msgXp)
  // }

  let countriesArr
  let count = 0
  if (intl.locale === "en") {
    countriesArr = countriesJson.countriesEn
  } else {
    countriesArr = countriesJson.countriesFr
  }
  const grandTotal = experiences.reduce((accumulator, item) => {
    return accumulator + item.totalPrice
  }, 0)
  experiences.forEach(element => {
    count += 1
  })

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <input
      id="daterange"
      type="text"
      value={value}
      onClick={onClick}
      onChange={() => {}}
      readOnly
      ref={ref}
    />
  ));

  return (
    <>
      <ConfirmPopUp formSubmitted={submitted}/>
      <section
        id="requestQuoteExp"
        className={`blk-form py-25`}
        data-scroll-section
      >
        <Container>
        {!isExpValid ?  (
            <Row className="isMobile">
              <Col md={12} className="no_experiences">
                <span className="no_experiences-text">
                  {intl.formatMessage({ id: "form_experience.error" })}
                  <Link
                    to={intl.formatMessage({
                      id: "form_experience.listing-link",
                    })}
                  >
                    {intl.formatMessage({
                      id: "form_experience.error-link-txt",
                    })}
                  </Link>
                </span>
              </Col>
            </Row>
          ): (
            <Row className="isMobile">
              <Col md={12} className="no_experiences">
                <span className="no_experiences-text">
                  {intl.formatMessage({ id: "form_experience.fill-form-txt" })}
                  {/* <Link
                      to={intl.formatMessage({
                        id: "form_experience.listing-link",
                      })}
                    >
                    {intl.formatMessage({ id: "form_experience.error-link-txt" })}
                  </Link> */}
                </span>
              </Col>
            </Row>
          )}
          <Row>
            <Col md={8} className="colFormulaire">
            {!isExpValid ?  (
            <Row className="isDesktop">
              <Col md={12} className="no_experiences">
                <span className="no_experiences-text">
                  {intl.formatMessage({ id: "form_experience.error" })}
                  <Link
                    to={intl.formatMessage({
                      id: "form_experience.listing-link",
                    })}
                  >
                    {intl.formatMessage({ id: "form_experience.error-link-txt" })}
                  </Link>
                </span>
              </Col>
            </Row>
          ): (
            <Row className="isDesktop">
              <Col md={12} className="no_experiences">
                <span className="no_experiences-text">
                    {intl.formatMessage({ id: "form_experience.fill-form-txt" })}
                    {/* <Link
                      to={intl.formatMessage({
                        id: "form_experience.listing-link",
                      })}
                    >
                    {intl.formatMessage({ id: "form_experience.error-link-txt" })}
                  </Link> */}
                </span>
              </Col>
            </Row>
          )}
              <h2>{intl.formatMessage({ id: "form_experience.titre_1" })}</h2>
              <form
                onSubmit={handleSubmit(submitHandlerContact)}
                noValidate={true}
              >
                <div className={classHide}>
                  <Row>
                    <Col>
                      <FormControl
                        sx={{ m: 1, width: 373 }}
                        required
                        error={errors.title}
                      >
                        <InputLabel htmlFor="title">
                          {intl.formatMessage({ id: "form_inputs.title" })}
                        </InputLabel>
                        <Select
                          id="title"
                          label="title"
                          {...register("title", { required: true })}
                        >
                          <MenuItem value="Mr.">
                            {locale == "en" ? "Mr." : "M."}
                          </MenuItem>
                          <MenuItem value="Mrs.">
                            {locale == "en" ? "Mrs." : "Mme"}
                          </MenuItem>
                          <MenuItem value="Ms.">
                            {locale == "en" ? "Ms." : "Mlle"}
                          </MenuItem>
                        </Select>
                        {errors.title && errors.title.type === "required" && (
                          <FormHelperText>
                            {intl.formatMessage({
                              id: "form_validation.required",
                            })}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="colFlex">
                      <FormControl required error={errors.first_name}>
                        <InputLabel htmlFor="first_name">
                          {intl.formatMessage({ id: "form_inputs.first_name" })}
                        </InputLabel>
                        <Input
                          id="first_name"
                          onKeyUp={(e)=> {
                            setFirstName(e.target.value)
                          }}
                          {...register("first_name", {
                            required: true,
                          })}
                        />
                        {errors.first_name &&
                          errors.first_name.type === "required" && (
                            <FormHelperText>
                              {intl.formatMessage({
                                id: "form_validation.required",
                              })}
                            </FormHelperText>
                          )}
                      </FormControl>
                      <FormControl required error={errors.last_name}>
                        <InputLabel htmlFor="last_name">
                          {intl.formatMessage({ id: "form_inputs.last_name" })}
                        </InputLabel>
                        <Input
                          id="last_name"
                          onKeyUp={(e)=> {
                            setlastName(e.target.value)
                          }}
                          {...register("last_name", {
                            required: true,
                          })}
                        />
                        {errors.last_name &&
                          errors.last_name.type === "required" && (
                            <FormHelperText>
                              {intl.formatMessage({
                                id: "form_validation.required",
                              })}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="colFlex">
                      <FormControl
                        sx={{ m: 1, width: 373 }}
                        required
                        error={errors.email}
                      >
                        <InputLabel htmlFor="email">
                          {intl.formatMessage({ id: "form_inputs.email" })}
                        </InputLabel>
                        <Input
                          id="email"
                          {...register("email", {
                            required: true,
                            pattern: pattern.email,
                          })}
                        />
                        {errors.email && errors.email.type === "pattern" && (
                          <FormHelperText>
                            {intl.formatMessage({
                              id: "form_validation.email_pattern",
                            })}
                          </FormHelperText>
                        )}
                        {errors.email && errors.email.type === "required" && (
                          <FormHelperText>
                            {intl.formatMessage({
                              id: "form_validation.required",
                            })}
                          </FormHelperText>
                        )}
                      </FormControl>
                      <FormControl
                        className="blkPhoneNum blkTelNum"
                        error={errors.telephone}
                        required
                      >
                        <InputLabel htmlFor="telephone">
                          {intl.formatMessage({ id: "form_inputs.telephone" })}
                        </InputLabel>
                        <MuiTelInput
                          id="telephone"
                          disableFormatting
                          forceCallingCode
                          onKeyUp={handleKeyUpPhone}
                          onChange={handlePhoneChange}
                          value={phoneNumber}
                          defaultCountry={"FR"}
                          langOfCountryName={intl.locale}
                        />
                        <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained Mui-required css-1wc848c-MuiFormHelperText-root">
                          {(phoneNumberError ||
                            (submitted && value === "")) && (
                            <span className="error" severity="error">
                              {intl.formatMessage({
                                id: "form_validation.required",
                              })}
                            </span>
                          )}{" "}
                        </p>
                      </FormControl>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="colFlex">
                      <FormControl
                        sx={{ m: 1, width: 373 }}
                        required
                        error={errors.country}
                      >
                        <InputLabel htmlFor="country">
                          {intl.formatMessage({ id: "form_inputs.country" })}
                        </InputLabel>
                        <Select
                          id="country"
                          label="Country"
                          onChange={handleChange}
                          {...register("country", { required: true })}
                        >
                          {countriesArr.map((country, index) => {
                            return (
                              <MenuItem key={index} value={country.en}>
                                {intl.locale === "en" ? country.en : country.fr}
                              </MenuItem>
                            )
                          })}
                        </Select>
                        {errors.country && errors.country.type === "required" && (
                          <FormHelperText>
                            {intl.formatMessage({
                              id: "form_validation.required",
                            })}
                          </FormHelperText>
                        )}
                      </FormControl>
                      <FormControl
                        sx={{ m: 1, width: 373 }}
                        required
                        error={errors.resident}
                      >
                        <InputLabel htmlFor="resident">
                          {intl.formatMessage({ id: "form_inputs.resident" })}
                        </InputLabel>
                        <Select
                          id="resident"
                          label="resident"
                          {...register("resident", { required: true })}
                        >
                          <MenuItem value="Yes">
                            {locale == "en" ? "Yes" : "Oui"}
                          </MenuItem>
                          <MenuItem value="No">
                            {locale == "en" ? "No" : "Non"}
                          </MenuItem>
                        </Select>
                        {errors.resident &&
                          errors.resident.type === "required" && (
                            <FormHelperText>
                              {intl.formatMessage({
                                id: "form_validation.required",
                              })}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Col>
                  </Row>
                  <Row>
                    <div className="bookingOptions">
                      <Row>
                        <Col>
                          <FormControl
                            sx={{ m: 1, width: 373 }}
                            required
                            error={errors.already_booked_your_room}
                            className="colChoix"
                          >
                            <InputLabel htmlFor="already_booked_your_room">
                              {intl.formatMessage({
                                id: "form_experience.room",
                              })}
                            </InputLabel>
                            <Controller
                              render={props => (
                                <ToggleButtonGroup
                                  className="toggleBtn"
                                  exclusive={true}
                                  aria-label="already_booked_your_room"
                                  value={alreadyBookedRoom}
                                  onChange={handleAlreadyBookRoomChange}
                                  {...props}
                                >
                                  <ToggleButton value="Yes" key="Yes">
                                    {intl.formatMessage({
                                      id: "form_inputs.option_yes",
                                    })}
                                  </ToggleButton>
                                  <ToggleButton value="No" key="No">
                                    {intl.formatMessage({
                                      id: "form_inputs.option_no",
                                    })}
                                  </ToggleButton>
                                </ToggleButtonGroup>
                              )}
                              name="already_booked_your_room"
                              control={control}
                            />
                            <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained Mui-required css-1wc848c-MuiFormHelperText-root">
                              {(alreadyBookedError ||
                                (submitted && value === "")) && (
                                <span className="error" severity="error">
                                  {intl.formatMessage({
                                    id: "form_validation.required",
                                  })}
                                </span>
                              )}
                            </p>
                          </FormControl>
                        </Col>
                      </Row>
                      {alreadyBookedRoom ===
                        intl.formatMessage({ id: "Yes" }) && (
                        <>
                          <Row>
                            <Col className="colChoixPlatform">
                              <FormControl
                                sx={{ m: 1, width: 373 }}
                                required
                                error={errors.which_platform_did_you_use}
                                className="colChoix"
                              >
                                <InputLabel htmlFor="which_platform_did_you_use">
                                  {intl.formatMessage({
                                    id: "form_experience.platform.title",
                                  })}
                                </InputLabel>
                                <Controller
                                  render={props => (
                                    <ToggleButtonGroup
                                      exclusive={true}
                                      aria-label="which_platform_did_you_use"
                                      value={whichPlatform}
                                      onChange={handleWhichPlatform}
                                      {...props}
                                    >
                                      <ToggleButton
                                        value="Agence de voyage en ligne"
                                        key="Agence de voyage en ligne"
                                      >
                                        {intl.formatMessage({
                                          id: "form_experience.platform.agence_enligne",
                                        })}
                                      </ToggleButton>
                                      <ToggleButton
                                        value="Directement avec nous"
                                        key="Directement avec nous"
                                      >
                                        {intl.formatMessage({
                                          id: "form_experience.platform.Directement",
                                        })}
                                      </ToggleButton>
                                      <ToggleButton
                                        value="Agence de voyage"
                                        key="Agence de voyage"
                                      >
                                        {intl.formatMessage({
                                          id: "form_experience.platform.agence_de_voyage",
                                        })}
                                      </ToggleButton>
                                    </ToggleButtonGroup>
                                  )}
                                  name="which_platform_did_you_use"
                                  control={control}
                                />
                                <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained Mui-required css-1wc848c-MuiFormHelperText-root">
                                  {(whichPlatformError ||
                                    (submitted && value === "")) && (
                                    <span className="error" severity="error">
                                      {intl.formatMessage({
                                        id: "form_validation.required",
                                      })}
                                    </span>
                                  )}{" "}
                                </p>
                                {whichPlatform ===
                                  "Agence de voyage en ligne" && (
                                  <>
                                    <Row className="booking_hotel-row">
                                      <Col className="booking_hotel">
                                        <FormControl
                                          variant="standard"
                                          sx={{ m: 1, width: 373 }}
                                          required
                                          error={
                                            errors.which_online_platform_did_you_use
                                          }
                                        >
                                          <InputLabel htmlFor="which_platform_did_you_use">
                                            {intl.formatMessage({
                                              id: "form_experience.platform.label",
                                            })}
                                          </InputLabel>
                                          <Select
                                            id="which_online_platform_did_you_use"
                                            {...register(
                                              "which_online_platform_did_you_use",
                                              {
                                                required: true,
                                              }
                                            )}
                                          >
                                            <MenuItem value="Booking.com">
                                              {intl.formatMessage({
                                                id: "form_experience.platform.which_platform.option_1",
                                              })}
                                            </MenuItem>
                                            <MenuItem value="Expedia.com">
                                              {intl.formatMessage({
                                                id: "form_experience.platform.which_platform.option_2",
                                              })}
                                            </MenuItem>
                                            <MenuItem value="Tripadvisor.com">
                                              {intl.formatMessage({
                                                id: "form_experience.platform.which_platform.option_3",
                                              })}
                                            </MenuItem>
                                            <MenuItem value="Others">
                                              {intl.formatMessage({
                                                id: "form_experience.platform.which_platform.option_4",
                                              })}
                                            </MenuItem>
                                          </Select>
                                          {errors.which_online_platform_did_you_use &&
                                            errors
                                              .which_online_platform_did_you_use
                                              .type === "required" && (
                                              <FormHelperText>
                                                {intl.formatMessage({
                                                  id: "form_validation.required",
                                                })}
                                              </FormHelperText>
                                            )}
                                        </FormControl>
                                        <FormControl
                                          sx={{ m: 1, width: 373 }}
                                          required
                                          error={errors.hotel}
                                        >
                                          <InputLabel htmlFor="hotel">
                                            {intl.formatMessage({
                                              id: "form_inputs.hotel",
                                            })}
                                          </InputLabel>
                                          <Select
                                            id="hotel"
                                            label="hotel"
                                            defaultValue={hotelQuery}
                                            {...register("hotel", {
                                              required: true,
                                            })}
                                          >
                                            {/* <MenuItem value="Heritage Le Telfair Golf & Wellness Resort">
                                              {intl.formatMessage({
                                                id: "maps.heritage-telfair",
                                              })}
                                            </MenuItem>
                                            <MenuItem value="Heritage Awali Golf & Spa Resort">
                                              {intl.formatMessage({
                                                id: "maps.awali",
                                              })}
                                            </MenuItem>
                                            <MenuItem value="Heritage The Villas">
                                              {intl.formatMessage({
                                                id: "maps.thevillas",
                                              })}
                                            </MenuItem> */}
                                            {hotelOptions.map((hotel, index) => (
                                              <MenuItem key={index} value={hotel.value}>
                                                {hotel.label}
                                            </MenuItem>
                                            ))}
                                          </Select>
                                          {errors.hotel &&
                                            errors.hotel.type ===
                                              "required" && (
                                              <FormHelperText>
                                                {intl.formatMessage({
                                                  id: "form_validation.required",
                                                })}
                                              </FormHelperText>
                                            )}
                                        </FormControl>
                                      </Col>
                                    </Row>
                                    <div className="datePicker_booking">
                                      <div className="roomSelect">
                                        <div className="dateRangePicker">
                                          <div className="colDatePicker focused">
                                            <FormControl>
                                              <div className="MuiFormControl-root">
                                                <InputLabel htmlFor="daterange">
                                                  {intl.formatMessage({
                                                    id: "form_inputs.arrival-dates",
                                                  })}
                                                </InputLabel>
                                                <DatePicker
                                                  onChange={
                                                    handleStartDateChange
                                                  }
                                                  selected={startDate}
                                                  dateFormat="dd MMM yy"
                                                  id="daterange"
                                                  monthsShown={1}
                                                  minDate={new Date()}
                                                  startDate={startDate}
                                                  endDate={endDate}
                                                  locale={intl.locale}
                                                  customInput={<CustomInput />}
                                                />
                                              </div>
                                            </FormControl>
                                          </div>
                                        </div>
                                        <div className="dateRangePicker">
                                          <div className="colDatePicker focused">
                                            <FormControl>
                                              <div className="MuiFormControl-root">
                                                <InputLabel htmlFor="daterange">
                                                  {intl.formatMessage({
                                                    id: "form_inputs.departure-dates",
                                                  })}
                                                </InputLabel>
                                                <DatePicker
                                                  onChange={date =>
                                                    setEndDate(date)
                                                  }
                                                  selected={endDate}
                                                  dateFormat="dd MMM yy"
                                                  id="daterange"
                                                  monthsShown={1}
                                                  minDate={startDate}
                                                  startDate={startDate}
                                                  endDate={endDate}
                                                  locale={intl.locale}
                                                  customInput={<CustomInput />}
                                                />
                                              </div>
                                            </FormControl>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                                {whichPlatform === "Directement avec nous" && (
                                  <>
                                    <Row className="booking_hotel-row DirectWithUs">
                                      <Col className="booking_hotel">
                                    <FormControl
                                      sx={{ m: 1, width: 373 }}
                                      required
                                      error={errors.hotel}
                                    >
                                      <InputLabel htmlFor="hotel">
                                        {intl.formatMessage({
                                          id: "form_inputs.hotel",
                                        })}
                                      </InputLabel>
                                      <Select
                                        id="hotel"
                                        label="hotel"
                                        defaultValue={hotelQuery}
                                        {...register("hotel", {
                                          required: true,
                                        })}
                                      >
                                            {/* <MenuItem value="Heritage Le Telfair Golf & Wellness Resort">
                                          {intl.formatMessage({
                                            id: "maps.heritage-telfair",
                                          })}
                                        </MenuItem>
                                            <MenuItem value="Heritage Awali Golf & Spa Resort">
                                          {intl.formatMessage({
                                            id: "maps.awali",
                                          })}
                                        </MenuItem>
                                            <MenuItem value="Heritage The Villas">
                                          {intl.formatMessage({
                                            id: "maps.thevillas",
                                          })}
                                            </MenuItem> */}
                                            {hotelOptions.map((hotel, index) => (
                                              <MenuItem key={index} value={hotel.value}>
                                                {hotel.label}
                                        </MenuItem>
                                            ))}
                                      </Select>
                                      {errors.hotel &&
                                            errors.hotel.type ===
                                              "required" && (
                                          <FormHelperText>
                                            {intl.formatMessage({
                                              id: "form_validation.required",
                                            })}
                                          </FormHelperText>
                                        )}
                                    </FormControl>
                                      </Col>
                                    </Row>

                                    <div className="datePicker_booking">
                                      <div className="roomSelect">
                                        <div className="dateRangePicker">
                                          <div className="colDatePicker focused">
                                            <FormControl>
                                              <div className="MuiFormControl-root">
                                                <InputLabel htmlFor="daterange">
                                                  {intl.formatMessage({
                                                    id: "form_inputs.arrival-dates",
                                                  })}
                                                </InputLabel>
                                                <DatePicker
                                                  onChange={
                                                    handleStartDateChange
                                                  }
                                                  selected={startDate}
                                                  dateFormat="dd MMM yy"
                                                  id="daterange"
                                                  monthsShown={1}
                                                  minDate={new Date()}
                                                  startDate={startDate}
                                                  endDate={endDate}
                                                  locale={intl.locale}
                                                  customInput={<CustomInput />}
                                                />
                                              </div>
                                            </FormControl>
                                          </div>
                                        </div>
                                        <div className="dateRangePicker">
                                          <div className="colDatePicker focused">
                                            <FormControl>
                                              <div className="MuiFormControl-root">
                                                <InputLabel htmlFor="daterange">
                                                  {intl.formatMessage({
                                                    id: "form_inputs.departure-dates",
                                                  })}
                                                </InputLabel>
                                                <DatePicker
                                                  onChange={date =>
                                                    setEndDate(date)
                                                  }
                                                  selected={endDate}
                                                  dateFormat="dd MMM yy"
                                                  id="daterange"
                                                  monthsShown={1}
                                                  minDate={startDate}
                                                  startDate={startDate}
                                                  endDate={endDate}
                                                  locale={intl.locale}
                                                  customInput={<CustomInput />}
                                                />
                                              </div>
                                            </FormControl>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                                {whichPlatform === "Agence de voyage" && (
                                  <>
                                    <Row className="booking_hotel-row">
                                      <Col className="booking_hotel">
                                        <FormControl
                                          sx={{ m: 1, width: 373 }}
                                          required
                                          error={
                                            errors.which_agency_did_you_use
                                          }
                                        >
                                          <InputLabel htmlFor="which_platform_did_you_use">
                                            {intl.formatMessage({
                                              id: "form_experience.agency.label",
                                            })}
                                          </InputLabel>
                                          <Input
                                            onChange={handleWhichPlatformValue}
                                            id="which_agency_did_you_use"
                                            label="which_agency_did_you_use"
                                            defaultValue={hotelQuery}
                                            {...register(
                                              "which_agency_did_you_use",
                                              {
                                                required: true,
                                              }
                                            )}
                                          />
                                          {/* <Select
                                      onChange={handleWhichPlatformValue}
                                      id="which_platform_did_you_use"
                                      label="which_platform_did_you_use"
                                      defaultValue={hotelQuery}
                                      {...register("which_platform_did_you_use", {
                                        required: true,
                                      })}
                                    >
                                      <MenuItem value="Agence_1">
                                        {intl.formatMessage({
                                          id: "form_experience.agency.agency_1",
                                        })}
                                      </MenuItem>
                                      <MenuItem value="Agence_2">
                                        {intl.formatMessage({
                                          id: "form_experience.agency.agency_2",
                                        })}
                                      </MenuItem>
                                      <MenuItem value="Agence_3">
                                        {intl.formatMessage({
                                          id: "form_experience.agency.agency_3",
                                        })}
                                      </MenuItem>
                                    </Select> */}
                                          {errors.which_agency_did_you_use &&
                                        errors.which_agency_did_you_use.type === "required" && (
                                              <FormHelperText>
                                                {intl.formatMessage({
                                                  id: "form_validation.required",
                                                })}
                                              </FormHelperText>
                                            )}
                                        </FormControl>
                                        <FormControl
                                          sx={{ m: 1, width: 373 }}
                                          required
                                          error={errors.hotel}
                                        >
                                          <InputLabel htmlFor="hotel">
                                            {intl.formatMessage({
                                              id: "form_inputs.hotel",
                                            })}
                                          </InputLabel>
                                          <Select
                                            id="hotel"
                                            label="hotel"
                                            defaultValue={hotelQuery}
                                            {...register("hotel", {
                                              required: true,
                                            })}
                                          >
                                            {/* <MenuItem value="Heritage Le Telfair Golf & Wellness Resort">
                                              {intl.formatMessage({
                                                id: "maps.heritage-telfair",
                                              })}
                                            </MenuItem>
                                            <MenuItem value="Heritage Awali Golf & Spa Resort">
                                              {intl.formatMessage({
                                                id: "maps.awali",
                                              })}
                                            </MenuItem>
                                            <MenuItem value="Heritage The Villas">
                                              {intl.formatMessage({
                                                id: "maps.thevillas",
                                              })}
                                            </MenuItem> */}
                                            {hotelOptions.map((hotel, index) => (
                                              <MenuItem key={index} value={hotel.value}>
                                                {hotel.label}
                                            </MenuItem>
                                            ))}
                                          </Select>
                                          {errors.hotel &&
                                            errors.hotel.type ===
                                              "required" && (
                                              <FormHelperText>
                                                {intl.formatMessage({
                                                  id: "form_validation.required",
                                                })}
                                              </FormHelperText>
                                            )}
                                        </FormControl>
                                      </Col>
                                    </Row>

                                    <div className="datePicker_booking">
                                      <div className="roomSelect">
                                        <div className="dateRangePicker">
                                          <div className="colDatePicker focused">
                                            <FormControl>
                                              <div className="MuiFormControl-root">
                                                <InputLabel htmlFor="daterange">
                                                  {intl.formatMessage({
                                                    id: "form_inputs.arrival-dates",
                                                  })}
                                                </InputLabel>
                                                <DatePicker
                                                  onChange={
                                                    handleStartDateChange
                                                  }
                                                  selected={startDate}
                                                  dateFormat="dd MMM yy"
                                                  id="daterange"
                                                  monthsShown={1}
                                                  minDate={new Date()}
                                                  startDate={startDate}
                                                  endDate={endDate}
                                                  locale={intl.locale}
                                                  customInput={<CustomInput />}
                                                />
                                              </div>
                                            </FormControl>
                                          </div>
                                        </div>
                                        <div className="dateRangePicker">
                                          <div className="colDatePicker focused">
                                            <FormControl>
                                              <div className="MuiFormControl-root">
                                                <InputLabel htmlFor="daterange">
                                                  {intl.formatMessage({
                                                    id: "form_inputs.departure-dates",
                                                  })}
                                                </InputLabel>
                                                <DatePicker
                                                  onChange={date =>
                                                    setEndDate(date)
                                                  }
                                                  selected={endDate}
                                                  dateFormat="dd MMM yy"
                                                  id="daterange"
                                                  monthsShown={1}
                                                  minDate={startDate}
                                                  startDate={startDate}
                                                  endDate={endDate}
                                                  locale={intl.locale}
                                                  customInput={<CustomInput />}
                                                />
                                              </div>
                                            </FormControl>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </FormControl>
                            </Col>
                          </Row>
                        </>
                      )}
                      {alreadyBookedRoom ===
                        intl.formatMessage({ id: "No" }) && (
                        <>
                          <Row>
                            <Col className="colChoixRoom">
                              <div className="colChoixRoom__container">
                              <FormControl
                                sx={{ m: 1, width: 373 }}
                                required
                                error={errors.already_booked_your_room}
                                className="colChoix"
                              >
                                <InputLabel htmlFor="do_you_want_to_book_your_room">
                                  {intl.formatMessage({
                                    id: "form_experience.will_reserve",
                                  })}
                                </InputLabel>
                                <Controller
                                  render={props => (
                                    <ToggleButtonGroup
                                      className="toggleBtn"
                                      exclusive
                                      aria-label="do_you_want_to_book_your_room"
                                      value={willBookRoom}
                                      onChange={handleWillBookRoomChange}
                                      {...props}
                                    >
                                      <ToggleButton value="Yes" key="Yes">
                                        {intl.formatMessage({
                                          id: "form_inputs.option_yes",
                                        })}
                                      </ToggleButton>
                                      <ToggleButton value="No" key="No">
                                        {intl.formatMessage({
                                          id: "form_inputs.option_no",
                                        })}
                                      </ToggleButton>
                                    </ToggleButtonGroup>
                                  )}
                                  name="do_you_want_to_book_your_room"
                                  control={control}
                                />
                                <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained Mui-required css-1wc848c-MuiFormHelperText-root">
                                  {(wiiBookError ||
                                    (submitted && value === "")) && (
                                    <span className="error" severity="error">
                                      {intl.formatMessage({
                                        id: "form_validation.required",
                                      })}
                                    </span>
                                  )}{" "}
                                </p>
                              </FormControl>
                              {willBookRoom === "Yes" && (
                                <>
                                  <FormControl
                                    sx={{ m: 1, width: 373 }}
                                    required
                                    error={errors.hotel}
                                  >
                                    <InputLabel htmlFor="hotel">
                                      {intl.formatMessage({
                                        id: "form_inputs.hotel",
                                      })}
                                    </InputLabel>
                                    <Select
                                      id="hotel"
                                      label="hotel"
                                      defaultValue={hotelQuery}
                                      {...register("hotel", { required: true })}
                                    >
                                      {/* <MenuItem value="Heritage Le Telfair Golf & Wellness Resort">
                                        {intl.formatMessage({
                                          id: "maps.heritage-telfair",
                                        })}
                                      </MenuItem>
                                      <MenuItem value="Heritage Awali Golf & Spa Resort">
                                        {intl.formatMessage({
                                          id: "maps.awali",
                                        })}
                                      </MenuItem>
                                      <MenuItem value="Heritage The Villas">
                                        {intl.formatMessage({
                                          id: "maps.thevillas",
                                        })}
                                      </MenuItem> */}
                                      {hotelOptions.map((hotel, index) => (
                                        <MenuItem key={index} value={hotel.value}>
                                          {hotel.label}
                                      </MenuItem>
                                      ))}
                                    </Select>
                                    {errors.hotel &&
                                      errors.hotel.type === "required" && (
                                        <FormHelperText>
                                          {intl.formatMessage({
                                            id: "form_validation.required",
                                          })}
                                        </FormHelperText>
                                      )}
                                  </FormControl>
                                  <div className="roomSelect">
                                    <FormControl
                                      sx={{ m: 1, width: 373 }}
                                      required
                                      error={errors.adults}
                                    >
                                      <InputLabel htmlFor="adults">
                                        {intl.formatMessage({
                                          id: "form_inputs.adults",
                                        })}
                                      </InputLabel>
                                      <Select
                                        id="adults"
                                        label="adults"
                                        {...register("adults", {
                                          required: true,
                                        })}
                                      >
                                        <MenuItem value="1">1</MenuItem>
                                        <MenuItem value="2">2</MenuItem>
                                        <MenuItem value="3">3</MenuItem>
                                        <MenuItem value="4">4</MenuItem>
                                        <MenuItem value="5">5</MenuItem>
                                        <MenuItem value="6">6</MenuItem>
                                        <MenuItem value="7">7</MenuItem>
                                        <MenuItem value="8">8</MenuItem>
                                        <MenuItem value="9">9</MenuItem>
                                        <MenuItem value="10">10</MenuItem>
                                        <MenuItem value="11">11</MenuItem>
                                        <MenuItem value="12">12</MenuItem>
                                        <MenuItem value="13">13</MenuItem>
                                        <MenuItem value="14">14</MenuItem>
                                        <MenuItem value="15">15</MenuItem>
                                        <MenuItem value="15+">15+</MenuItem>
                                      </Select>
                                      {errors.adults &&
                                        errors.adults.type === "required" && (
                                          <FormHelperText>
                                            {intl.formatMessage({
                                              id: "form_validation.required",
                                            })}
                                          </FormHelperText>
                                        )}
                                    </FormControl>
                                    {willBookRoom ===
                                    intl.formatMessage({ id: "No" }) ? (
                                      <></>
                                    ) : (
                                      <FormControl
                                        sx={{ m: 1, width: 373 }}
                                        required
                                        error={errors.number_of_rooms}
                                      >
                                        <InputLabel htmlFor="number_of_rooms">
                                          {intl.formatMessage({
                                            id: "form_inputs.number_of_rooms",
                                          })}
                                        </InputLabel>
                                        <Select
                                          id="number_of_rooms"
                                          label="number_of_rooms"
                                          {...register("number_of_rooms", {
                                            required: true,
                                          })}
                                          disabled={
                                            willBookRoom == "No" ? true : false
                                          }
                                        >
                                          <MenuItem value="1">1</MenuItem>
                                          <MenuItem value="2">2</MenuItem>
                                          <MenuItem value="3">3</MenuItem>
                                          <MenuItem value="4">4</MenuItem>
                                          <MenuItem value="5">5</MenuItem>
                                          <MenuItem value="6">6</MenuItem>
                                          <MenuItem value="7">7</MenuItem>
                                          <MenuItem value="8">8</MenuItem>
                                          <MenuItem value="9">9</MenuItem>
                                          <MenuItem value="10">10</MenuItem>
                                        </Select>
                                        {errors.number_of_rooms &&
                                          errors.number_of_rooms.type ===
                                            "required" && (
                                            <FormHelperText>
                                              {intl.formatMessage({
                                                id: "form_validation.required",
                                              })}
                                            </FormHelperText>
                                          )}
                                      </FormControl>
                                    )}
                                  </div>
                                  <div className="roomSelect">
                                    <FormControl sx={{ m: 1, width: 373 }}>
                                      <InputLabel htmlFor="children">
                                        {intl.formatMessage({
                                          id: "form_inputs.children",
                                        })}
                                      </InputLabel>
                                      <Select
                                        id="children"
                                        label="children"
                                        {...register("children", {
                                          required: false,
                                        })}
                                      >
                                        <MenuItem value="0">0</MenuItem>
                                        <MenuItem value="1">1</MenuItem>
                                        <MenuItem value="2">2</MenuItem>
                                        <MenuItem value="3">3</MenuItem>
                                        <MenuItem value="4">4</MenuItem>
                                        <MenuItem value="5">5</MenuItem>
                                        <MenuItem value="6">6</MenuItem>
                                        <MenuItem value="7">7</MenuItem>
                                        <MenuItem value="8">8</MenuItem>
                                        <MenuItem value="9">9</MenuItem>
                                      </Select>
                                    </FormControl>
                                    <FormControl sx={{ m: 1, width: 373 }}>
                                      <InputLabel htmlFor="teen">
                                        {intl.formatMessage({
                                          id: "form_inputs.teen",
                                        })}
                                      </InputLabel>
                                      <Select
                                        id="teen"
                                        label="teen"
                                        {...register("teen", {
                                          required: false,
                                        })}
                                      >
                                        <MenuItem value="0">0</MenuItem>
                                        <MenuItem value="1">1</MenuItem>
                                        <MenuItem value="2">2</MenuItem>
                                        <MenuItem value="3">3</MenuItem>
                                        <MenuItem value="4">4</MenuItem>
                                        <MenuItem value="5">5</MenuItem>
                                        <MenuItem value="6">6</MenuItem>
                                        <MenuItem value="7">7</MenuItem>
                                        <MenuItem value="8">8</MenuItem>
                                        <MenuItem value="9">9</MenuItem>
                                      </Select>
                                    </FormControl>
                                  </div>
                                  <div className="roomSelect">
                                    <div className="dateRangePicker">
                                      <div className="colDatePicker focused">
                                        <FormControl>
                                          <div className="MuiFormControl-root">
                                            <InputLabel htmlFor="daterange">
                                              {intl.formatMessage({
                                                id: "form_inputs.arrival-dates",
                                              })}
                                            </InputLabel>
                                            <DatePicker
                                              onChange={handleStartDateChange}
                                              selected={startDate}
                                              dateFormat="dd MMM yy"
                                              id="daterange"
                                              monthsShown={1}
                                              minDate={new Date()}
                                              startDate={startDate}
                                              endDate={endDate}
                                              locale={intl.locale}
                                              customInput={<CustomInput />}
                                            />
                                          </div>
                                        </FormControl>
                                      </div>
                                    </div>
                                    <div className="dateRangePicker">
                                      <div className="colDatePicker focused">
                                        <FormControl>
                                          <div className="MuiFormControl-root">
                                            <InputLabel htmlFor="daterange">
                                              {intl.formatMessage({
                                                id: "form_inputs.departure-dates",
                                              })}
                                            </InputLabel>
                                            <DatePicker
                                              onChange={date =>
                                                setEndDate(date)
                                              }
                                              selected={endDate}
                                              dateFormat="dd MMM yy"
                                              id="daterange"
                                              monthsShown={1}
                                              minDate={startDate}
                                              startDate={startDate}
                                              endDate={endDate}
                                              locale={intl.locale}
                                              customInput={<CustomInput />}
                                            />
                                          </div>
                                        </FormControl>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </div>
                  </Row>
                  <Row>
                    <Col className="contact-message">
                      <FormControl
                        required
                        error={errors.contact_message}
                        fullWidth
                      >
                        <InputLabel htmlFor="message">
                          {intl.formatMessage({ id: "form_inputs.message" })}
                        </InputLabel>
                        <Input
                          id="contact_message"
                          multiline
                          {...register("contact_message", { required: true })}
                        />
                        {errors.contact_message &&
                          errors.contact_message.type === "required" && (
                            <FormHelperText>
                              {intl.formatMessage({
                                id: "form_validation.required",
                              })}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormControl
                        required
                        error={errors.opt_in}
                        className="radio-form"
                      >
                        <Controller
                          rules={{ required: true }}
                          control={control}
                          name="opt_in"
                          render={({ field }) => (
                            <RadioGroup {...field}>
                              <FormControlLabel
                                value="Oui"
                                control={<Radio />}
                                label={intl.formatMessage({
                                  id: "form_inputs.opt_in_yes",
                                })}
                              />
                              <FormControlLabel
                                value="Non"
                                control={<Radio />}
                                label={intl.formatMessage({
                                  id: "form_inputs.opt_in_no",
                                })}
                              />
                            </RadioGroup>
                          )}
                        />
                        {errors.opt_in && errors.opt_in.type === "required" && (
                          <FormHelperText className="helper-required">
                            {intl.formatMessage({
                              id: "form_validation.required",
                            })}
                          </FormHelperText>
                        )}
                        <FormHelperText className="form-helper-policy">
                          {intl.formatMessage({
                            id: "form_inputs.opt_in_helper",
                          })}{" "}
                          <Link
                            to={intl.formatMessage({
                              id: "privacy_policy.link",
                            })}
                          >
                            {intl.formatMessage({ id: "privacy_policy.title" })}
                          </Link>
                        </FormHelperText>
                      </FormControl>
                    </Col>
                  </Row>
                  <input
                    type={"hidden"}
                    value={travellingDate}
                    id="travelling_dates"
                    {...register("travelling_dates")}
                  />
                  <input
                    type={"hidden"}
                    id="message"
                    {...register("message")}
                  />
                  <input type="hidden" name="record_type" value="0124H000000Qnw1" {...register("record_type")}></input>
                  <input type={"hidden"} id="market" {...register("market")} />
                  <Row className="contact-submit experienceBtn">
                    {/*<ReCaptcha*/}
                    {/*  action="request_quote"*/}
                    {/*  siteKey="6Lc-c8QSAAAAAERYd1S2PLKI3r0LuswOx9CRE1ku"*/}
                    {/*  onVerify={token => submit(token)}*/}
                    {/*  submitted={submitted}*/}
                    {/*/>*/}
                    <button
                      className={`btn ${
                        !isExpValid || !isFormValid ? "disabled" : "btnPrimary"
                      }`}
                      onClick={handleSubmitForm}
                      type="submit"
                      disabled={!isExpValid || !isFormValid ? true : ""}
                    >
                      <span>
                        {intl.formatMessage({ id: "form_inputs.send" })}
                      </span>
                    </button>
                  </Row>
                </div>
                {formResponse && (
                  <Row className="success-elem">
                    <Col>
                      <div className="form-message" style={{ color: "red" }}>
                        {formResponse}
                      </div>
                    </Col>
                  </Row>
                )}
              </form>
            </Col>
            <Col md={4}>
              <div className="panierList">
                <div className="pannier_count">
                  <h2>
                    {intl.formatMessage({ id: "form_experience.titre_2" })}
                  </h2>
                  {experiences.length > numRenderedCart && (
                    <div>
                      <div className="numbers-panier">
                        <span>{count}</span>
                      </div>
                      {/* <span>experiences</span> */}
                    </div>
                  )}
                </div>
                <CollectableExperienceCart />
                <div className="grand-total">
                  <div className="textTotal">
                    {intl.formatMessage({ id: "add-to-cart.total" })}
                  </div>
                  <div className="totalFigures">
                    <span>
                      {intl.locale == "en"
                        ? `€ ${grandTotal}*`
                        : `${grandTotal}*€`}
                    </span>
                  </div>
                </div>
                <div className="prix-indicatif">
                  <span>
                    {intl.formatMessage({ id: "add-to-cart.prix-indicatif" })}
                    <a
                      href={intl.formatMessage({
                        id: "add-to-cart.prix-indicatif-link",
                      })}
                      target="_blank"
                    >
                      {intl.formatMessage({
                        id: "add-to-cart.prix-indicatif-link-text",
                      })}
                    </a>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default RequestQuoteExperience
