import {
  FormControl,
  Input,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material"
import React, { useState, useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { Controller, useForm } from "react-hook-form"
import {
  errorMessages,
  getAccessToken,
  getSessionToken,
  pattern,
} from "./formUtils"
import "./forms.scss"
import { useIntl } from "gatsby-plugin-react-intl"
import axios from "axios"
import { getWebformURL } from "../../functions"
import { Link, navigate } from "gatsby"
import queryString from "query-string"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { DateTimePicker } from "@mui/x-date-pickers"
import * as moment from "moment"
import ReCaptcha from "@pittica/gatsby-plugin-recaptcha"
import countriesJson from './countries.json'
import { MuiTelInput } from "mui-tel-input"
import { hotelOptions } from "../../../services/form.service"
import ConfirmPopUp from "./confirmPopUp"
import { useContext } from "react"
import CartContext from "../../../context/CartContext"

const Contact = ({ thankYouPage }) => {
  const { setShow } = useContext(CartContext)
  const isSSR = typeof window === "undefined"
  const intl = useIntl()

  // Get hotel value from url
  const toProperCase = s => {
    return s.replace(/([a-z])([A-Z])/g, "$1 $2")
  }

  let parsed, hotelQuery
  if (!isSSR) {
    parsed = queryString.parse(window.location.search)
    hotelQuery = parsed?.hotel ? toProperCase(parsed.hotel) : ""

    if (hotelQuery === "Veranda Paul Et Virginie") {
      hotelQuery = "Veranda Paul et Virginie"
    }
    if (hotelQuery === "Veranda Pointe Aux Biches") {
      hotelQuery = "Veranda Pointe aux Biches"
    }
  }

  useEffect(() => {
    const inputPhone = document.querySelector("#telephone")
    inputPhone.setAttribute("maxlength", 15)
  }, [])

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    formState,
  } = useForm()
  const [formResponse, setFormResponse] = useState("")
  const { isSubmitSuccessful, isSubmitting, isValid } = formState
  const [submitted, setSubmitted] = useState(false)
  const [isFormValid, setIsFormValid] = React.useState(true)

  const submit = token => {}

  const [formClass, setFormClass] = useState("")

  // let classHide = !isSubmitting && isSubmitSuccessful ? "input-hide" : ""

  // if (formClass !== "") {
  //   classHide = formClass
  // }

  const [arriveVal, setArriveVal] = React.useState(null)

  const [value, setValue] = React.useState([null, null])
  const [reason, setReason] = useState("")
  const handleBookReason = event => {
    const getValue = event.target.value
    if (getValue === "Request Quote") {
      intl.locale === "en"
        ? navigate(`/en/mauritius-hotels/ask-quote`)
        : navigate(`/demandez-un-devis`)
    } else {
      setReason(getValue)
    }
  }
  //Choice for Booking
  const handleBookingChange = event => {
    const getSelectedVal = event.target.value

    const blkBookNo = document.getElementById("blkBookNo")
    const blkBookYes = document.getElementById("blkBookYes")
    const blkArrival = document.getElementById("blkArrival")
    const blkHotel = document.getElementById("blkHotel")

    if (getSelectedVal === "Yes") {
      blkBookYes.style.display = "inline-flex"
      blkBookNo.style.display = "none"
      blkHotel.style.display = "inline-flex"
      blkArrival.style.display = "inline-flex"
    } else if (getSelectedVal === "No") {
      blkBookNo.style.display = "inline-flex"
      blkBookYes.style.display = "none"
      blkArrival.style.display = "none"
      blkHotel.style.display = "inline-flex"
    }
  }

  const [firstName, setFirstName] = useState("")
  const [lastName, setlastName] = useState("")
  const [fullName, setFullName] = useState("")

  const [phoneNumberError, setPhoneNumberError] = useState(false)
  const [phoneNumber, setPhoneNumber] = React.useState("")

  const handlePhoneChange = value => {
    if (value === null) {
      setPhoneNumberError(true)
      setPhoneNumber("")
    } else {
      setPhoneNumberError(false)
      setPhoneNumber(value)
    }
  }

  const handleSubmitForm = () => {
    
    if (phoneNumber === "" || phoneNumber.length < 6) {
      setPhoneNumberError(true)
    } else {
      setPhoneNumberError(false)
    }
    
    if (phoneNumber === "" || phoneNumber.length < 6) return
    
    setSubmitted(true)
  }

  const handleKeyUpPhone = event => {
    if(event.target.value.length < 6) {
      setPhoneNumberError(true)
      setSubmitted(false)
    }
  }

  const [country, setCountry] = React.useState("")

  const handleChange = event => {
    setCountry(event.target.value)
  }
  let countriesArr;
  if (intl.locale === "en") {
    countriesArr = countriesJson.countriesEn
  } else {
    countriesArr = countriesJson.countriesFr
  }
  
  useEffect(()=> {
    setFullName(`${firstName} ${lastName}`)
  },[firstName, lastName])

  const submitHandlerContact = async (values, e) => {
    e.preventDefault()

    if(!submitted) return 
    
    setIsFormValid(false)
    setShow("show")

    if (values.first_name) {
      setFullName(values.first_name + " " + values.last_name)
    }

    values.name = values.first_name + " " + values.last_name

    if (reason) {
      values.reason_to_contact_us = reason
    }

    if (arriveVal) {
      values.arrival_date = moment(arriveVal).format("DD/MM/yyyy HH:mm")
    }

    values.phone = phoneNumber
    intl.locale === "en"
      ? (values.language_id = "en_US")
      : (values.language_id = "fr")
    values.marque = "Veranda Resorts"
    values.gdpr = values.gdpr === "Yes"
    const body = {
      webform_id: "webform_contact",
      values: values,
    }
    try {
      const sessionToken = await getSessionToken();
      const accessToken = await getAccessToken();
      const options = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "X-CSRF-Token": sessionToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
      };
      const response = await axios({
        method: "POST",
        url: getWebformURL(intl.locale),
          data: JSON.stringify(body),
          headers: options,
      });
      e.target.reset();

    } catch (error) {
      // console.log("submitFormError: ", error);
      setFormResponse("");
    }
  }
  return (
    <section className="blk-form pb-50 pt-50">
      <ConfirmPopUp formSubmitted={submitted}/>
      <Container>
        <form onSubmit={handleSubmit(submitHandlerContact)} noValidate={true}>
          <div>
            <Row>
              <Col>
                <FormControl
                  required
                  error={errors.already_booked_with_us}
                  sx={{ m: 1, width: 430 }}
                >
                  <InputLabel htmlFor="already_booked_with_us">
                    {intl.formatMessage({
                      id: "form_inputs.already_booked_with_us",
                    })}
                  </InputLabel>
                  <Select
                    id="already_booked_with_us"
                    label="already_booked_with_us"
                    {...register("already_booked_with_us", { required: true })}
                    onChange={handleBookingChange}
                  >
                    <MenuItem value="Yes">
                      {intl.formatMessage({ id: "form_inputs.option_yes" })}
                    </MenuItem>
                    <MenuItem value="No">
                      {intl.formatMessage({ id: "form_inputs.option_no" })}
                    </MenuItem>
                  </Select>
                  {errors.already_booked_with_us &&
                    errors.already_booked_with_us.type === "required" && (
                      <FormHelperText>
                        {intl.formatMessage({ id: "form_validation.required" })}
                      </FormHelperText>
                    )}
                </FormControl>

                <FormControl id="blkBookYes" sx={{ m: 1, width: 430 }}>
                  <InputLabel htmlFor="book_yes">
                    {intl.formatMessage({
                      id: "form_inputs.contact_reasons",
                    })}
                  </InputLabel>
                  <Select
                    id="book_yes"
                    label="book_yes"
                    onChange={handleBookReason}
                  >
                    <MenuItem value="Rooms">
                      {intl.formatMessage({
                        id: "contact_page.option_yes_reason_1",
                      })}
                    </MenuItem>
                    <MenuItem value="Activities">
                      {intl.formatMessage({
                        id: "contact_page.option_yes_reason_2",
                      })}
                    </MenuItem>
                    <MenuItem value="Restaurants">
                      {intl.formatMessage({
                        id: "contact_page.option_yes_reason_3",
                      })}
                    </MenuItem>
                    <MenuItem value="Service (Spa, Golf, Kids, ...)">
                      {intl.formatMessage({
                        id: "contact_page.option_yes_reason_4",
                      })}
                    </MenuItem>
                    <MenuItem value="Request Information">
                      {intl.formatMessage({
                        id: "contact_page.option_yes_reason_5",
                      })}
                    </MenuItem>
                  </Select>
                  {errors.book_yes && errors.book_yes.type === "required" && (
                    <FormHelperText>
                      {intl.formatMessage({ id: "form_validation.required" })}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl id="blkBookNo" sx={{ m: 1, width: 430 }}>
                  <InputLabel htmlFor="book_no">
                    {intl.formatMessage({
                      id: "form_inputs.contact_reasons",
                    })}
                  </InputLabel>
                  <Select
                    id="book_no"
                    label="book_no"
                    onChange={handleBookReason}
                  >
                    <MenuItem value="Request Quote">
                      {intl.formatMessage({
                        id: "contact_page.option_no_reason_1",
                      })}
                    </MenuItem>
                    <MenuItem value="Request Information about a hotel">
                      {intl.formatMessage({
                        id: "contact_page.option_no_reason_2",
                      })}
                    </MenuItem>
                    <MenuItem value="Jobs">
                      {intl.formatMessage({
                        id: "contact_page.option_no_reason_3",
                      })}
                    </MenuItem>
                    <MenuItem value="Sales / Communication / Media / PR">
                      {intl.formatMessage({
                        id: "contact_page.option_no_reason_4",
                      })}
                    </MenuItem>
                  </Select>
                  {errors.book_no && errors.book_no.type === "required" && (
                    <FormHelperText>
                      {intl.formatMessage({ id: "form_validation.required" })}
                    </FormHelperText>
                  )}
                </FormControl>
              </Col>
            </Row>
            <Row>
              <Col className="contact-details">
                <FormControl
                  id="blkHotel"
                  sx={{ m: 1, width: 430 }}
                  required
                  error={errors.hotel}
                >
                  <InputLabel htmlFor="hotel">
                    {intl.formatMessage({ id: "form_inputs.hotel" })}
                  </InputLabel>
                  <Select
                    id="hotel"
                    label="hotel"
                    defaultValue={hotelQuery}
                    {...register("hotel", { required: true })}
                  >
                    {hotelOptions.map((hotel, index) => (
                      <MenuItem key={index} value={hotel.value}>
                        {hotel.label}
                    </MenuItem>
                    ))}
                  </Select>
                  {errors.hotel && errors.hotel.type === "required" && (
                    <FormHelperText>
                      {intl.formatMessage({ id: "form_validation.required" })}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl
                  id="blkArrival"
                  className="blkCalendar"
                  sx={{ m: 1, width: 430 }}
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <InputLabel htmlFor="arrival_date">
                      {intl.formatMessage({ id: "form_inputs.arrival_date" })}
                    </InputLabel>
                    <DateTimePicker
                      id="arrival_date"
                      inputFormat="MM/dd/yyyy h:mm"
                      minDate={new Date()}
                      value={arriveVal}
                      onChange={newValue => {
                        setArriveVal(newValue)
                      }}
                      renderInput={params => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  {errors.arrival_date &&
                    errors.arrival_date.type === "required" && (
                      <FormHelperText>
                        {intl.formatMessage({ id: "form_validation.required" })}
                      </FormHelperText>
                    )}
                </FormControl>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormControl required error={errors.first_name}>
                  <InputLabel htmlFor="first_name">
                    {intl.formatMessage({ id: "form_inputs.first_name" })}
                  </InputLabel>
                  <Input
                    id="first_name"
                    name="first_name"
                    onKeyUp={(e)=> {
                      setFirstName(e.target.value)
                    }}
                    {...register("first_name", {
                      required: true,
                    })}
                  />
                  {errors.first_name && errors.first_name.type === "required" && (
                    <FormHelperText>
                      {intl.formatMessage({
                        id: "form_validation.required",
                      })}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl required error={errors.last_name}>
                  <InputLabel htmlFor="last_name">
                    {intl.formatMessage({ id: "form_inputs.last_name" })}
                  </InputLabel>
                  <Input
                    id="last_name"
                    onKeyUp={(e)=> {
                      setlastName(e.target.value)
                    }}
                    {...register("last_name", {
                      required: true,
                    })}
                  />
                  {errors.last_name && errors.last_name.type === "required" && (
                    <FormHelperText>
                      {intl.formatMessage({
                        id: "form_validation.required",
                      })}
                    </FormHelperText>
                  )}
                </FormControl>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormControl required error={errors.email}>
                  <InputLabel htmlFor="email">
                    {intl.formatMessage({ id: "form_inputs.email" })}
                  </InputLabel>
                  <Input
                    id="email"
                    {...register("email", {
                      required: true,
                      pattern: pattern.email,
                    })}
                  />
                  {errors.email && errors.email.type === "pattern" && (
                    <FormHelperText>
                      {intl.formatMessage({
                        id: "form_validation.email_pattern",
                      })}
                    </FormHelperText>
                  )}
                  {errors.email && errors.email.type === "required" && (
                    <FormHelperText>
                      {intl.formatMessage({ id: "form_validation.required" })}
                    </FormHelperText>
                  )}
                </FormControl>
              </Col>
            </Row>

            <Row>
              <Col className="colPhoneNum">
                <FormControl
                  className="blkPhoneNum"
                  required
                  error={errors.phone}
                >
                  <InputLabel htmlFor="phone">
                    {intl.formatMessage({ id: "form_inputs.telephone" })}
                  </InputLabel>
                  <MuiTelInput
                    id="telephone"
                    disableFormatting
                    forceCallingCode
                    onKeyUp={handleKeyUpPhone}
                    onChange={handlePhoneChange}
                    value={phoneNumber}
                    defaultCountry={"FR"}
                    langOfCountryName={intl.locale}
                  />
                  {errors.phone && errors.phone.type === "required" && (
                    <FormHelperText>
                      {intl.formatMessage({ id: "form_validation.required" })}
                    </FormHelperText>
                  )}
                  <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained Mui-required css-1wc848c-MuiFormHelperText-root">
                    {(phoneNumberError ||
                      (submitted && value === "")) && (
                      <span className="error" severity="error">
                        {intl.formatMessage({
                          id: "form_validation.required",
                        })}
                      </span>
                    )}{" "}
                  </p>
                </FormControl>
                <FormControl
                  required
                  error={errors.country}
                  sx={{ m: 1, width: 373 }}
                >
                  <InputLabel htmlFor="country">
                    {intl.formatMessage({ id: "form_inputs.country" })}
                  </InputLabel>
                  <Select
                    id="country"
                    label="Country"
                    onChange={handleChange}
                    {...register("country", { required: true })}
                  >
                    {countriesArr.map((country, index) => {
                      return (
                        <MenuItem key={index} value={country.en}>
                          {intl.locale === "en" ? country.en : country.fr}
                        </MenuItem>
                      )
                    })}
                  </Select>
                  {errors.country && errors.country.type === "required" && (
                    <FormHelperText>
                      {intl.formatMessage({
                        id: "form_validation.required",
                      })}
                    </FormHelperText>
                  )}
                </FormControl>
              </Col>
            </Row>

            <Row>
              <Col className="contact-message">
                <FormControl required error={errors.enquiry_details} fullWidth>
                  <InputLabel htmlFor="message">
                    {intl.formatMessage({ id: "form_inputs.message" })}
                  </InputLabel>
                  <Input
                    id="enquiry_details"
                    {...register("enquiry_details", { required: true })}
                    multiline
                  />
                  {errors.enquiry_details &&
                    errors.enquiry_details.type === "required" && (
                      <FormHelperText>
                        {intl.formatMessage({ id: "form_validation.required" })}
                      </FormHelperText>
                    )}
                </FormControl>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormControl
                  required
                  error={errors.gdpr}
                  className="radio-form"
                >
                  <Controller
                    rules={{ required: true }}
                    control={control}
                    name="gdpr"
                    render={({ field }) => (
                      <RadioGroup {...field}>
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label={intl.formatMessage({
                            id: "form_inputs.opt_in_yes",
                          })}
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label={intl.formatMessage({
                            id: "form_inputs.opt_in_no",
                          })}
                        />
                      </RadioGroup>
                    )}
                  />
                  {errors.gdpr && errors.gdpr.type === "required" && (
                    <FormHelperText className="helper-required">
                      {intl.formatMessage({ id: "form_validation.required" })}
                    </FormHelperText>
                  )}
                  <FormHelperText className="form-helper-policy">
                    {intl.formatMessage({ id: "form_inputs.opt_in_helper" })}{" "}
                    <Link
                      to={intl.formatMessage({ id: "privacy_policy.link" })}
                    >
                      {intl.formatMessage({ id: "privacy_policy.title" })}
                    </Link>
                  </FormHelperText>
                </FormControl>
              </Col>
            </Row>
            <Row className="contact-submit">
              {/*<ReCaptcha*/}
              {/*  action="contact"*/}
              {/*  siteKey="6Lc-c8QSAAAAAERYd1S2PLKI3r0LuswOx9CRE1ku"*/}
              {/*  onVerify={token => submit(token)}*/}
              {/*  submitted={submitted}*/}
              {/*/>*/}
              <button
                className={`btn ${
                  !isFormValid ? "disabled" : "btnPrimary"
                }`}
                onClick={handleSubmitForm}
                disabled={!isFormValid ? true : ""}
                type="submit"
              >
                <span>{intl.formatMessage({ id: "form_inputs.send" })}</span>
              </button>
            </Row>
          </div>
          {formResponse && (
            <Row className="success-elem">
              <Col>
                <div className="form-message" style={{ color: "red" }}>
                  {formResponse}
                </div>
              </Col>
            </Row>
          )}
          {!isSubmitting &&
            isSubmitSuccessful &&
            !formResponse && submitted &&
            navigate(`${thankYouPage.url}?titre=pagecontact&name=${fullName}`)}
        </form>
      </Container>
    </section>
  )
}

export default Contact
