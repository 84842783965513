import React, { useContext, useState } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Row } from "react-bootstrap"
import CartContext from "../../../context/CartContext"
import "./CollectableExperienceCart.scss"
import { useIntl } from "gatsby-plugin-react-intl"
import * as moment from "moment"
const CollectableExperienceCart = () => {
  const { experiences, setShow, setCartItem } = useContext(CartContext)
  const initialValue = 5
  const [next, setNext] = useState(initialValue)
  const allExperiences = experiences.length

  const handleMoreImage = () => {
    setNext(next + allExperiences - initialValue)
  }
  const handleLessImage = () => {
    setNext(next - allExperiences + initialValue)
  }

  const handleItem = experience => {
    setShow("show")
    setCartItem(experience)
  }
  const intl = useIntl()

  const listResults = experiences?.slice(0, next)?.map((experience, index) => (
    <div key={index} className="cart">
      <div className="upperRow">
        <div className="intro">
          <h2>{experience.pageTitle}</h2>
        </div>
        <div className="imgXP">
          <GatsbyImage
            image={getImage(experience.featuredImage)}
            alt={""}
            height="auto"
          />
        </div>
      </div>
      <div className="lowerRow">
        <div className="expDetails">
          <div>
            {intl.formatMessage({ id: "add-to-cart.no-of-adults" })}
            <span>{experience.numAdults}</span>
          </div>
          {experience.numChild && (
            <div>
              {intl.formatMessage({ id: "add-to-cart.no-of-children" })}
              <span>{experience.numChild}</span>
            </div>
          )}
          <div>
            {intl.formatMessage({ id: "add-to-cart.date-popup" })}
            <span>{moment(experience.startDate).format("DD MMMM YYYY")}</span>
          </div>
          <div>
            {intl.formatMessage({ id: "add-to-cart.price" })}
            <span>
              {intl.locale == "en"
                ? `€ ${experience.totalPrice}*`
                : `${experience.totalPrice}€*`}
            </span>
          </div>
        </div>
        <button className="btnDeleteXP" onClick={() => handleItem(experience)}>
          <i className="icon-delete"></i>
        </button>
      </div>
    </div>
  ))

  if (experiences?.length > next) {
    return (
      <div>
        {listResults}
        <div className="btn-cart">
          <button className="load_more" onClick={handleMoreImage}>
            {intl.formatMessage({ id: "show_more" })}
            <span class="icon-small-arrow"></span>
          </button>
        </div>
      </div>
    )
  } else if (experiences?.length <= initialValue) {
    return <div className="cart_wrapper">{listResults}</div>
  } else if (experiences?.length == allExperiences) {
    return (
      <div>
        {listResults}
        <div className="btn-cart">
          <button className="load_less" onClick={handleLessImage}>
            {intl.formatMessage({ id: "show_less" })}
            <span class="icon-small-arrow"></span>
          </button>
        </div>
      </div>
    )
  }
}

export default CollectableExperienceCart
